import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as WarningWhite } from "assets/icons/ErrorMessage/warning-white.svg";
import { ReactComponent as Success } from "assets/icons/Toast/success.svg";
import { handleFileType } from "utils/functions";
import { handleSetComplianceStatuses } from "utils/compliance";
import useWindowDimensions from "hooks/useWindowDimensions";
import FileInput from "components/General/Input/FileInput";
import Input from "components/General/Input/Input";
import ErrorMessage from "components/General/ErrorMessage/ErrorMessage";
import Button from "components/General/Button/Button";
import complianceSlice from "../slice";
import ImageModal from "./ImageModal";

const { actions } = complianceSlice;

const BusinessAccountForm = () => {
  const errorContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { merchantDetails: currentBusiness } = useSelector(
    (state) => state.marchants
  );
  const { complianceStatuses, compliance } = useSelector(
    (state) => state.compliance
  );
  const [convertingImage, setConvertingImage] = useState({});

  const emptyBusiness = {
    account_legal_name: currentBusiness?.account_legal_name || "",
    account_trade_name: currentBusiness?.account_trade_name || "",
    account_rc_number: currentBusiness?.account_rc_number || "",
    account_tin_number: currentBusiness?.account_tin_number || "",
    account_utility_photo: currentBusiness?.account_utility_photo_url || "",
    account_legal_cert: currentBusiness?.account_legal_cert_url || "",
  };
  const emptyFiles = {
    account_utility_photo: { type: "", url: "" },
    account_legal_cert: { type: "", url: "" },
  };
  const emptyImageModal = {
    show: false,
    type: "",
  };
  const [business, setBusiness] = useState({
    ...emptyBusiness,
  });
  const [files, setFiles] = useState({ ...emptyFiles });
  const [imageModal, setImageModal] = useState({ ...emptyImageModal });

  const { formErrors } = useSelector((state) => state.compliance);

  useEffect(() => {
    handleFiles();
  }, [
    business.account_utility_photo,
    business.account_legal_cert,
    currentBusiness,
  ]);
  useEffect(() => {
    setBusiness({ ...emptyBusiness });
  }, [currentBusiness]);

  const handleFiles = () => {
    const account_utility_photo = handleFileType(
      business?.account_utility_photo,
      "account_utility_photo"
    );
    const account_legal_cert = handleFileType(
      business?.account_legal_cert,
      "account_legal_cert"
    );
    setFiles({ ...account_utility_photo, ...account_legal_cert });
  };

  const handleChange = async (prop, val) => {
    setBusiness({ ...business, [prop]: val });
    if (prop === "account_utility_photo" || prop === "account_legal_cert") {
      const fileSize = (val?.size || 0) / 1024 ** 2;
      if (fileSize > 50) {
        dispatch(actions.setFormErrors({ ...formErrors, [prop]: true }));
      } else {
        dispatch(actions.setFormErrors({ ...formErrors, [prop]: false }));
      }
    }
  };
  const rcStatus = complianceStatuses?.stats?.rcStatus;
  const tinNoStatus = complianceStatuses?.stats?.tinNoStatus;
  const utilityStatus = complianceStatuses?.stats?.utilityStatus;
  const regCertificateStatus = complianceStatuses?.stats?.regCertificateStatus;
  const errors = handleSetComplianceStatuses(
    compliance || currentBusiness
  )?.businessErrs;
  return (
    <form className="w-full ">
      <div className="sm:hidden flex justify-center items-center  w-full px-[22px] py-3 bg-white border-b-1/2 border-grey-bordercolor absolute top-0 right-0 left-0">
        <Link className="text-grey-text text-sm" to="/dashboard/compliance/">
          Back
        </Link>

        <span className="text-sm text-black w-full text-center">
          Business account setup
        </span>
      </div>
      <p className="sm:hidden text-grey-text text-xs font-thin pt-8 px-2 sm:px-5">
        Tell us more about your business to get your account setup
      </p>
      <div
        ref={errorContainerRef}
        className="flex flex-col justify-start items-start space-y-1 w-full absolute sm:relative top-[80px] sm:top-0 left-0 right-0"
      >
        {errors
          ?.filter((item) => item)
          .map((item, i) => (
            <div
              key={i + item}
              className="w-full flex justify-center items-center bg-red text-white text-xs font-thin px-4 py-2"
            >
              <span className="">
                <WarningWhite className="stroke-current mr-2" />
              </span>
              {item}
            </div>
          ))}
      </div>

      <div
        className={`space-y-4 w-full px-2 sm:px-5 pt-5 sm:pt-4 pb-8`}
        style={{
          marginTop: `${
            width < 640 ? errorContainerRef?.current?.scrollHeight : 0
          }px`,
        }}
      >
        <div className="flex flex-col justify-start items-start space-y-2 w-full">
          <span className="text-xs font-light text-grey-text">
            Business legal name
          </span>
          <Input
            value={business.account_legal_name}
            onChangeFunc={(val) => handleChange("account_legal_name", val)}
            placeholder="Enter business legal name"
            required
            isDisabled
            icon={
              (regCertificateStatus === "accepted" ||
                regCertificateStatus === "approved") && (
                <Success className="w-[34px] h-[34px]" />
              )
            }
          />
        </div>

        <div className="flex flex-col justify-start items-start space-y-2 w-full">
          <span className="text-xs font-light text-grey-text">
            Trading name
          </span>
          <Input
            value={business.account_trade_name}
            onChangeFunc={(val) => handleChange("account_trade_name", val)}
            placeholder="Enter trading name"
            required
            isDisabled
            icon={
              (regCertificateStatus === "accepted" ||
                regCertificateStatus === "approved") && (
                <Success className="w-[34px] h-[34px]" />
              )
            }
          />
        </div>

        <div className="flex flex-col justify-start items-start space-y-2 w-full">
          <span className="text-xs font-light text-grey-text">
            Business registration number
          </span>
          <Input
            value={business.account_rc_number}
            onChangeFunc={(val) => handleChange("account_rc_number", val)}
            placeholder="Enter business registration number"
            isDisabled
            isError={
              rcStatus === "rejected" &&
              business.account_rc_number === currentBusiness.account_rc_number
            }
            icon={
              (rcStatus === "accepted" || rcStatus === "approved") && (
                <Success className="w-[34px] h-[34px]" />
              )
            }
          />
        </div>

        <div className="flex flex-col justify-start items-start space-y-2 w-full">
          <span className="text-xs font-light text-grey-text">
            Business tax identification number
          </span>
          <Input
            value={business.account_tin_number}
            onChangeFunc={(val) => handleChange("account_tin_number", val)}
            placeholder="Enter business tax number"
            isDisabled
            isError={
              tinNoStatus === "rejected" &&
              business.account_tin_number === currentBusiness.account_tin_number
            }
            icon={
              (tinNoStatus === "accepted" || tinNoStatus === "approved") && (
                <Success className="w-[34px] h-[34px]" />
              )
            }
          />
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          <div className="flex justify-between items-end w-full">
            <FileInput
              placeholder="Upload a utility bill photo"
              title="Utility Bill  (Uploaded file should show business name and address)"
              file={
                convertingImage?.account_utility_photo
                  ? { name: "Converting .heic image to .jpg" }
                  : business.account_utility_photo
              }
              onChangeFunc={(val) =>
                handleChange("account_utility_photo", val[0])
              }
              handleLoading={(status) =>
                setConvertingImage({ account_legal_cert: status })
              }
              isDisabled
              type="pdf_image"
              className={
                files?.account_utility_photo?.url
                  ? "w-[calc(100%-95px)]"
                  : "w-full"
              }
              isError={
                utilityStatus === "rejected" &&
                typeof business.account_utility_photo === "string"
              }
              icon={
                (utilityStatus === "accepted" ||
                  utilityStatus === "approved") && (
                  <Success className="w-[34px] h-[34px]" />
                )
              }
            />

            {files?.account_utility_photo?.url &&
              (files?.account_utility_photo?.type === "pdf" ? (
                <a
                  href={files?.account_utility_photo?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="button" text="Preview" />
                </a>
              ) : (
                <Button
                  type="button"
                  text="Preview"
                  isDisabled={convertingImage?.account_utility_photo}
                  onClick={() =>
                    setImageModal({
                      show: true,
                      type: files?.account_utility_photo?.url,
                    })
                  }
                />
              ))}
          </div>
          <div
            className={`h-${
              formErrors.account_legal_cert ? "7" : "4"
            } w-100 flex justify-start items-center`}
          >
            {formErrors.account_utility_photo && (
              <ErrorMessage
                message="File size cannot be over 50mb"
                className="py-2"
              />
            )}
          </div>
          <div className="flex justify-between items-end w-full space-x-2">
            <FileInput
              placeholder="Upload your business registration certificate"
              title="Business registration certificate"
              file={
                convertingImage?.account_legal_cert
                  ? { name: "Converting .heic image to .jpg" }
                  : business.account_legal_cert
              }
              onChangeFunc={(val) => handleChange("account_legal_cert", val[0])}
              handleLoading={(status) =>
                setConvertingImage({ account_legal_cert: status })
              }
              isDisabled
              type="pdf_image"
              className={
                files?.account_legal_cert?.url
                  ? "w-[calc(100%-95px)]"
                  : "w-full"
              }
              isError={
                regCertificateStatus === "rejected" &&
                typeof business.account_legal_cert === "string"
              }
              icon={
                (regCertificateStatus === "accepted" ||
                  regCertificateStatus === "approved") && (
                  <Success className="w-[34px] h-[34px]" />
                )
              }
            />
            {files?.account_legal_cert?.url &&
              (files?.account_legal_cert?.type === "pdf" ? (
                <a
                  href={files?.account_legal_cert?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="button" text="Preview" />
                </a>
              ) : (
                <Button
                  type="button"
                  text="Preview"
                  isDisabled={convertingImage?.account_legal_cert}
                  onClick={() =>
                    setImageModal({
                      show: true,
                      type: files?.account_legal_cert?.url,
                    })
                  }
                />
              ))}
          </div>
          <div
            className={`h-${
              formErrors.account_legal_cert ? "7" : "6"
            } w-100 flex justify-start items-center`}
          >
            {formErrors.account_legal_cert && (
              <ErrorMessage
                message="File size cannot be over 50mb"
                className="py-2"
              />
            )}
          </div>
        </div>
      </div>
      {}
      {imageModal.show && (
        <ImageModal
          active={imageModal.show}
          toggler={() => setImageModal({ ...emptyImageModal })}
          photo={imageModal.type}
        />
      )}
    </form>
  );
};

export default BusinessAccountForm;
