import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { ReactComponent as WarningWhite } from "assets/icons/ErrorMessage/warning-white.svg";
import { ReactComponent as Success } from "assets/icons/Toast/success.svg";
import cleanPayload from "utils/cleanPayload";
import { handleSetComplianceStatuses } from "utils/compliance";
import useWindowDimensions from "hooks/useWindowDimensions";
import Input from "components/General/Input/Input";
import DatePickerComponent from "components/General/DatePicker";
import complianceSlice from "../slice";

const { actions } = complianceSlice;
const BVN_DIGITS_LENGTH = 11;

const BankVerificationForm = () => {
  const errorContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { currentBusiness } = useSelector((state) => state.auth);
  const { compliance, complianceStatuses } = useSelector(
    (state) => state.compliance
  );
  const { merchantDetails: profileData } = useSelector(
    (state) => state.marchants
  );
  const { tribe_account_id } = currentBusiness;
  const defaultBirthday = new Date("01/01/1990");

  const emptyBusiness = {
    bvn_number: profileData?.account_holder_details?.holder_legal_num || "",
    bvn_five_digit: profileData?.bvn_five_digit || "",
    date_of_birth:
      (profileData?.date_of_birth && new Date(profileData?.date_of_birth)) ||
      defaultBirthday,
  };

  const [business, setBusiness] = useState({
    ...emptyBusiness,
  });

  useEffect(() => {
    setBusiness({ ...emptyBusiness });
  }, [profileData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { bvn_number, bvn_five_digit, date_of_birth } = business;
    let payload = {
      ...(bvn_number && {
        bvn_number:
          bvn_number === profileData.holder_legal_num ? "" : bvn_number,
      }),

      ...(bvn_five_digit && {
        bvn_five_digit:
          bvn_five_digit === profileData.bvn_five_digit ? "" : bvn_five_digit,
      }),
      ...(date_of_birth && {
        date_of_birth:
          date_of_birth === profileData.date_of_birth
            ? ""
            : moment(date_of_birth).format("YYYY-MM-DD"),
      }),
    };

    payload = cleanPayload(payload);
    const formD = new FormData();

    Object.keys(payload).forEach((key) => {
      formD.append(key, payload[key]);
    });

    dispatch(
      actions.updateBvnCompliance({
        formD,
        tribe_account_id,
      })
    );
  };

  const handleChange = async (prop, val, maxLength) => {
    setBusiness({
      ...business,
      [prop]: maxLength ? val.slice(0, maxLength) : val,
    });
  };

  const errors = handleSetComplianceStatuses(
    compliance || currentBusiness
  )?.bvnErrs;

  const bvnStatus = complianceStatuses?.stats?.bvnStatus;

  const submissionIsAccpeted =
    bvnStatus === "accepted" || bvnStatus === "approved";

  return (
    <form onSubmit={handleSubmit} className="w-full ">
      <div className="sm:hidden flex justify-center items-center  w-full px-[22px] py-3 bg-white border-b-1/2 border-grey-bordercolor absolute top-0 right-0 left-0">
        <Link className="text-grey-text text-sm" to="/dashboard/compliance/">
          Back
        </Link>

        <span className="text-sm text-black w-full text-center">
          Bank verification number
        </span>
      </div>
      <p className="sm:hidden text-grey-text text-xs font-thin pt-8 px-2 sm:px-5">
        Please verify your BVN to get your account setup
      </p>
      <div
        ref={errorContainerRef}
        className="flex flex-col justify-start items-start space-y-1 w-full absolute sm:relative top-[80px] sm:top-0 left-0 right-0"
      >
        {errors
          ?.filter((item) => item)
          .map((item, i) => (
            <div
              key={i + item}
              className="w-full flex justify-center items-center bg-red text-white text-xs font-thin px-4 py-2"
            >
              <span className="">
                <WarningWhite className="stroke-current mr-2" />
              </span>
              {item}
            </div>
          ))}
      </div>

      <div
        className={`space-y-4 w-full px-2 sm:px-5 pt-5 sm:pt-4 pb-8`}
        style={{
          marginTop: `${
            width < 640 ? errorContainerRef?.current?.scrollHeight : 0
          }px`,
        }}
      >
        <div className="flex flex-col justify-start items-start space-y-2 w-full">
          <span className="text-xs font-light text-grey-text">
            Bank verification number
          </span>
          <Input
            value={business.bvn_number}
            onChangeFunc={(val) =>
              handleChange("bvn_number", val, BVN_DIGITS_LENGTH)
            }
            placeholder="Enter your BVN"
            isDisabled
            type="numberInput"
            icon={
              submissionIsAccpeted && <Success className="w-[34px] h-[34px]" />
            }
          />
        </div>

        <div className="flex flex-col justify-start items-start space-y-2 w-full">
          <span className="text-xs font-light text-grey-text">
            Last 5 digits of the mobile number linked to your BVN
          </span>
          <Input
            value={business.bvn_five_digit}
            onChangeFunc={(val) => handleChange("bvn_five_digit", val, 5)}
            placeholder="Enter the last five digits"
            isDisabled
            type="numberInput"
          />
        </div>

        <DatePickerComponent
          label="Your date of birth"
          labelClass="!text-xs !font-light text-grey-text !mb-2"
          onChange={(val) => handleChange("date_of_birth", val)}
          name="date_of_birth"
          isD
          value={business.date_of_birth}
          placeholder="Select your Your date of birth"
          disabled
          containerStyle={{ bottom: "285px" }}
        />
      </div>
    </form>
  );
};

export default BankVerificationForm;
